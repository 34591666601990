.hero-cutoff {
    background-image: url(../res/images/hero-image.webp);
    background-position: center;
    position: relative;
}

.hero-cutoff::after {
    content: '';
    pointer-events: none;
    position: absolute;
    width: 500px;
    height: 120%;
    top: -10%;
    left: -300px;
    transform: rotate(15deg);
}