.animated-text {
    position: relative;
    animation: 0.0001s linear 0.7s 1 normal forwards running fadeInAnimation;
    color: white;
    display: inline-block;
    background-size: 100% 100%;
}

.animated-text::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 0;
    height: 100%;
    background: rgb(34, 34, 34);
    background-size: 100% 100%;
    animation: 1.4s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal forwards running revBlock;
}

.animated-text-white {
    position: relative;
    animation: 0.0001s linear 0.7s 1 normal forwards running fadeInAnimationWhite;
    color: rgb(34, 34, 34);
    display: inline-block;
    background-size: 100% 100%;
}

.animated-text-white::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 0;
    height: 100%;
    background: white;
    background-size: 100% 100%;
    animation: 1.4s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal forwards running revBlock;
}

@keyframes fadeInAnimationWhite {
    0% {
        color: rgb(34, 34, 34);
    }
    100%{
        color: white;
    }
}

@keyframes fadeInAnimation {
    0% {
        color: white;
    }
    100%{
        color: rgb(34, 34, 34);
    }
}

@keyframes revBlock {
    0%{
        left: 0;
        width: 0
    }
    50%{
        left:0;
        width:100%
    }
    100%{
        left:100%;
        width:0
    }
}